.middle {
    background-image: radial-gradient(circle, #d9f8f7, #e3f9ff, #effaff, #f9fcff, #ffffff);
    font-family: Arial, Helvetica, sans-serif;
}

.baner {
    margin-top: 30px;
    background: rgb(24, 24, 24);
    border-bottom: 2px solid #71cbc9;
    border-top: 1px solid #71cbc9;
}

.baner .baner_img {
    width: 100%;
}

.partneri {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 130px;
}

.partneri .partneri_tekst {
    grid-column: 1;
    text-align: right;
    background: black;
    color: white;
    padding: 13px 15% 0 0;
}

.partneri .partneri_tekst .tekst_nasi {
    font-size: 22px;
    margin-bottom: -14px;
    margin-top: 7px;
}

.partneri .partneri_tekst .tekst_partneri {
    font-size: 30px;
}

.partneri .partneri_logovi {
    grid-column: 2;
    display: flex;
    background-image: url("/background-Dark-Grey.jpg");
    border-left: 1px solid #71cbc9;
}

.partneri .partneri_logovi button {
    width: 13%;
    height: 90px;
    background: none;
    margin: 20px 0 20px 2.5%;
    border: 0;
    padding: 0;
}

.partneri .partneri_logovi button img{
    width: 50%;
    height: auto;
}

.partneri .partneri_logovi button:hover img {
    transform: scale(1.5);
    transition: all 1s ease;
}

.middle_projekti {
    width: 90%;
    margin: 5% 0 7% 5%;
}

.middle_projekti p{
    font-size: 50px;
    color: black;
    letter-spacing: 2px;
    margin-bottom: -15px;
}

.projekti {
    display: flex;
}

.projekti div{
    display: block;
    height: 500px;
}

.projekti button{
    width: 100%;
    border: 0;
    margin: 0;
    padding: 1px;
}

.projekti button img{
    width: 100%;
    height: 250px;
}

.projekti button .img_mp{
    height: 500px;
}

.projekti button:hover img {
    opacity: 0.8;
}

.projekti_response {
    display: none;
}

.slider_partneri {
    display: none;
}

@media (max-width: 850px) {
    .baner .baner_img{
        height: 60px;
    }
    
    .middle_projekti {
        display: none;
    }
    
    .projekti_response {
        margin: 30px 0 30px 0;
        padding: 0;
        width: 100%;
        position: relative;
        /* border: 10px solid white;
        box-shadow: 0px 0px 5px 2px; */
        display: block;
    }
    
    .projekti_response p {
        font-size: 30px;
        color: black;
        letter-spacing: 2px;
        margin-bottom: -10px;
    }
    
    .projekti_response img {
        width: 100%;
        height: 250px;
    }
    
    .projekti_response button {
        border: none;
        background-color: black;
        font-size: 30px;
        position: absolute;
        top: 50%;
        color: white;
        cursor: pointer;
    }
    
    .projekti_response .prew {
        left: 0;
        border-radius: 0px 10px 10px 0;
    }
    
    .projekti_response .next {
        right: 0%;
        border-radius: 10px 0 0 10px;
    }

    .partneri {
        display: block;
    }

    .partneri .partneri_tekst {
        height: 50px;
        text-align: center;
        padding: 0;
    }

    .partneri .partneri_tekst .tekst_nasi{
        font-size: 13px;
    }

    .partneri .partneri_tekst .tekst_partneri{
        font-size: 18px;
        margin-top: -25px;
    }    

    /* .partneri .partneri_logovi {
        border: none;
        height: 90px;
    }
    
    .partneri .partneri_logovi button {
        width: 50%;
        height: 90px;
        background: #343a40;
        margin: 0 1% 0 1%;
        border: 0;
        padding: 0;
    }
    
    .partneri .partneri_logovi button img{
        width: 100%;
        height: auto;
    } */

    .partneri .partneri_logovi {
        display: none;
    }

    .partneri .slider_partneri {
        display: flex;
        width: 100%;
        height: 140px;
        background-image: url("/background-Dark-Grey.jpg");
    }

    .partneri .slider_partneri div {
        width: 25%;
        height: 100px;
        margin: 30px 4% 30px 4%;
        background-image: none;
    }

    .partneri .slider_partneri .btn1_sp{
        width: 80px;
        height: 80px;
        border: 0;
        background: url("/partner1.png");
        background-size: 100%;
        animation: slide1 12s infinite;
    }

    .partneri .slider_partneri .btn2_sp{
        width: 80px;
        height: 80px;
        border: 0;
        background: url("/partner2.jpg");
        background-size: 100%;
        animation: slide2 12s infinite;
    }

    .partneri .slider_partneri .btn3_sp{
        width: 80px;
        height: 80px;
        border: 0;
        background: url("/partner3.png");
        background-size: 100%;
        animation: slide3 12s infinite;
    }

    @keyframes slide1 {
        33% {
            background: url("/partner2.jpg");
            background-size: 100%;
        }
        66% {
            background: url("/partner3.png");
            background-size: 100%;
        }
        100% {
            background: url("/partner1.png");
            background-size: 100%;
        }
    }

    @keyframes slide2 {
        33% {
            background: url("/partner3.png");
            background-size: 100%;
        }
        66% {
            background: url("/partner1.png");
            background-size: 100%;
        }
        100% {
            background: url("/partner2.jpg");
            background-size: 100%;
        }
    }

    @keyframes slide3 {
        33% {
            background: url("/partner1.png");
            background-size: 100%;
        }
        66% {
            background: url("/partner2.jpg");
            background-size: 100%;
        }
        100% {
            background: url("/partner3.png");
            background-size: 100%;
        }
    }
}