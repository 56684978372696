.header {  
    display: grid;
    scroll-behavior: none;
    grid-template-columns: 8% 5% 70% 17%;
    grid-template-rows: 77px;
    background-color: black;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10000;
}
#projekti {
    color: #71cbc9;
    text-transform: uppercase;
    padding-top: 25px;
    text-align: center;
}
#clanoviHome {
    font-size: 22px;
    color: #71cbc9;
    text-transform: uppercase;
    text-decoration: none;
}

#projektiHome {
    font-size: 22px;
    color: #71cbc9;
    text-transform: uppercase;
    text-decoration: none;
}

#projekti:hover {
    color: white;
}

#clanovi {
    color: #71cbc9;
    text-transform: uppercase;
    padding-top: 25px;
    text-align: center;
}

#clanovi:hover {
    color: white;
}

.header .logo_header {
    grid-column: 1;
    background: black;
}

.logo_header button {
    width: 100%;
    border: 0;
    padding: 0;
    background: black;
}

.header .logo_header button img{
    width: 130px;
    /* padding-top: 7%; */
}

.header .desno_header {
    grid-column: 4;
    float: right;
    margin-top: 18px;
}

.header .desno_header button {
    width: 47px;
    height: 41px;
    border: 0;
    background: black;
    margin-left: 3px;
}

.header .desno_header button img{
    width: 100%;
    border: 1px solid #71cbc9;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px;
}

.NB_css {
    z-index: 1000;
}

.NB_css ul {
    grid-column: 3;
    margin: 0;
    padding: 0;
    list-style: none;
}

.NB_css ul li {
    float: left;
    width: 16.666%;
}

.NB_css ul li a{
    text-align: center;
    display: block;
    text-decoration: none;
    width: 100%;
    color: #71cbc9;
    text-transform: uppercase;
    padding-top: 25px;
    height: 77px;
}

.NB_css ul li a:hover{
    /* background-color: rgb(50, 50, 50); */ 
    color: white;
}

.NB_css ul li ul li{
    display: none;
    width: 200%;
    margin-left: -50%;
    background-color: black;
}

.NB_css ul li:hover ul li{
    display: block;
}

.NB_css ul li ul li ul li {
    height: 45px;
}
/* ODAVDE JE SAMO MENJANO */
.NB_css ul li ul li ul {
    position: absolute;
    display: none;
    margin: -77px 0 0 29%;
    width: 12%;
}

.NB_css ul li ul li ul li a {
    color: #71cbc9;
    height: 60px;
    font-size: 14px;
    padding-top: 25px;
    background-color: black;
}

/* DOVDE */

.NB_css ul li ul li:hover ul {
    display: block;
}
.timoviTekst {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0px;

}
.NB_response {
    position: absolute;
    background: black;
    width: 100%;
    z-index: 1000;
    height: 700px;
    display: none;
    opacity: 0.92;
    margin-top: -77px;
    text-align: center;
}

.NB_response ul {
    display: flex;
    list-style: none;
    margin-top: 75px;
}

.NB_response ul li {
    width: 16.666%;
    border-right: 1px solid white;
}

.NB_response ul li a {
    font-size: 22px;
    color: #71cbc9;
    text-transform: uppercase;
    text-decoration: none;
}
#checkboxTimovi{
    display: none;
}
.NB_response ul li ul {
    display: block;
    margin-top: 12px;
    padding-left: 0px;
}

.NB_response ul li ul li {
    display: block;
    border: none;
    width: 100%;
}

.NB_response ul li ul li a {
    font-size: 16px;
    color: white;
}

.header .tri_crte{
    grid-column: 2;
}

.header .tri_crte .checkbtn {
    cursor: pointer;
    margin-top: 22px;
    margin-left: 50%;
}

.header .tri_crte .img_bars {
    width: 30px;
    height: 33px;
}

.header .tri_crte #check {
    display: none;
}

.header .checkbtn1 {
    cursor: pointer;
    background: red;
}

.header #check1 {
    background: red;
}

.dugmeNavBar {
    position: relative;
    float: right;
    background-color: black;
    border: 0;
    margin-right: 1%;
    margin-top: 1%;
}

.dugmeTimovi {
    display: none;
}

@media (max-width: 850px){    

    .timoviTekst {
        color: white;
        font-size: 12px;
        margin-left: 10px;
        text-transform: uppercase;
    
    }
    .header {
        grid-template-columns: none;
    }

    #clanoviHome {
        font-size: 12px;
    }

    #projektiHome {
        font-size: 12px;
    }

    .header .logo_header button img{
        width: 130px;
        padding-top: 0%;
    }

    .NB_response ul li ul li .padajuciTimovi {
        display: none;
    }

    .NB_response ul li ul li .padajuciTimovi li {
        width: 240px;
    }

    .fi_menu_timovi {
        margin-left: -14px;
        margin-top: -5px;
    }

    .dugmeTimovi {
        display: inline-block;
        background: none;
        border: none;
        margin: 0 0 0 -4px;
        color: #71cbc9;
        font-size: 14px;
    }

    .header .logo_header {
        width: 60%;
    }

    .header .tri_crte {
        display: block;
    }

    .header .tri_crte .checkbtn {
        float: right;
        margin-top: 13px;
    }

    .header .tri_crte .img_bars {
        width: 50px;
        height: 51px;
        float: right;
        margin-right: 30px;
    }

    .header .desno_header {
        display: none;
    }

    .NB_css {
        display: none;
    }

    .header .tri_crte #check:checked ~ .NB_response {
        display: block;
    }

    /* .NB_css #check:checked ~ ul li ul{
        left: 7%;
        top: 150px
    } */

    .NB_response {
        opacity: 1;
        margin-top: 0;
    }

    .NB_response ul {
        display: block;
        margin-top: 20px;
        padding-left: 3%;
    }

    .NB_response ul li {
        border: none;
    }

    .NB_response ul li a{
        font-size: 12px;
    }

    /* .NB_response ul li {
        display: flex;
    } */

    .NB_response ul li ul {
        margin: 10px 0 10px 15%;
        border-left: 2px solid #71cbc9;
        border-radius: 20%;
        text-align: left;
    }

    .NB_response ul li ul li {
        width: 160px;
    }

    .NB_response ul li ul li a {
        font-size: 12px;
        margin-left: 10px;
    }
    .dugmeNavBar {
        display: none;
    }

    .NB_response ul li ul li ul{
        border-radius: 0;
        margin-left: 35px;
    }
}

@media (max-width: 1180px) {
    .header .desno_header button {
        width: 39px;
        height: 41px;
    }

    .header .logo_header button img{
        width: 100px;
        padding-top: 10%;
    }
}

@media (max-width: 1000px) {
    .header .desno_header button {
        width: 35px;
        height: 41px;
    }
    .header .logo_header button img{
        width: 100px;
        padding-top: 10%;
    }
}

@media (max-width: 900px) {
    .header .desno_header button {
        width: 33px;
        height: 41px;
    }
    .header .logo_header button img{
        width: 100px;
        padding-top: 10%;
    }
}
@media (max-width: 849px) {
    .header .logo_header button img{
        width: 130px;
        padding-top: 0%;
    }
}