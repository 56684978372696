.middle_kontakt {
    background-image: url("/background-Dark-Grey.jpg");
    margin: 0;
    padding: 50px 0 70px 0;
    text-align: center;
}

.middle_kontakt .kontaktirajte_nas {
    margin-bottom: 30px;
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.kontakt_fi {
    width: 60%;
    height: 150px;
    margin: 0 20% 0 20%;
    display: flex;
}
.kontaktiTekst {
    font-weight: bold;
    font-size: 18px;
}

.kontakt_fi .icons_kontakt {
    display: block;
    width: 33.333%;
    text-align: center;
    color: white;
}

.kontakt_fi .icons_kontakt .icon_fi{
    width: 100%;
    height: 35px;
    color: #71cbc9;
}

.kontakt_fi .icons_kontakt div1{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.FormContainer{
    border:1px solid #71cbc9;
    -webkit-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    -moz-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    width: 60%;
    margin: 20px 20% 0 20%;
    padding: 20px 0 110px 0;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.naslov_cf {
    color: white;
    font-size: 24px;
}

.form-control1 {
    width: 46%;
    margin: 0 4% 0 0;
}

.form-control2 {
    width: 46%;
    margin: 0 0 0 4%;
}

.controlForm {
    margin-left: 5px;
    text-align: center;
}


.mdbSend {
    margin-bottom: 30px;
}

.mdbSend:hover{
    background: black;
    color: black;
}

.text-center{
    margin-top: 10px;
}

.poruka {
    width:80%;
    margin-left: 10%;
}

.form-control3, .form-control4{
    width: 100%;
}

.naslov_cf {
    margin-bottom: 20px;
}

.btn_kontakt {
    position: absolute;
    margin-left: 45%;
    width: 10%;
    height: 40px;
    margin-top: -80px;
    border-radius: 5%;
}

.btn_kontakt button {
    background: none;
    color: white;
    width: 100%;
    height: 40px;
    border: 1px solid white;
    border-radius: 5%;
    font-size: 13px;
    font-family: monospace, 'Lucida Sans';
    letter-spacing: 3px;
    text-transform: uppercase;
}

.btn_kontakt:hover button{
    border: 1px solid #71cbc9;
    color: #71cbc9;
    transform: scale(1.03);
}

.kontakt_fi_responsive {
    display: none;
}

@media (max-width: 850px){
    .middle_kontakt .kontaktirajte_nas {
        font-size: 24px;
        margin-bottom: 200px;
    }

    .kontakt_fi {
        position: absolute;
        display: inline-block;
        margin: -170px 0 0 -190px;
    }

    .kontakt_fi .icons_kontakt{
        display: flex;
        margin-bottom: 15px;
        font-size: 15px;
        text-align: left;
        width: 155%;


    }

    .kontakt_fi .icons_kontakt .icon_fi {
        width: 70px;
    }

    .kontakt_fi .icons_kontakt div {
        width: 200px;
        text-align: left;
    }

    .kontakt_fi .icons_kontakt .icon_fi{
        height: 30px;
    }

    .kontakt_fi .icons_kontakt div1{
        display: none;
    }

    .FormContainer {
        width: 95%;
        margin: 300px 0 0 2.5%;
    }

    .poruka {
        width: 100%;
        margin-left: 0;
    }

    .btn_kontakt {
        width: 25%;
        margin-left: 37.5%;
    }
}