.Footer1 {
    width: 100%;
    height: 700px;
    background: black;
    border-top: 1px solid #71cbc9;
    padding: 50px 0 50px 0;
    font-family: Arial, Helvetica, sans-serif;
}

.gornji_deo {
    display: flex;
    width: 100%;
    padding-left: 6%;
}

.logo_footer {
    width: 20%;
    margin: 40px 0 0 40%;
}

.logo_footer div{
    width: 100%;
}

.logo_footer div img{
    width: 100%;
}

.sajt_footer {
    width: 15%;
}

.kontakt_footer {
    width: 25%;
}

.sajt_footer p {
    color: #71cbc9;
    font-size: 24px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    text-align: center;
}

.sajt_footer ul{
    list-style: none;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0;
}

.sajt_footer ul li {
    text-align: center;
}

.sajt_footer ul li a{
    color: white;
    text-decoration: none;
}

.kontakt_footer p{
    color: #71cbc9;
    font-size: 24px;
    letter-spacing: 3px;
    margin-bottom: 15px;
    text-align: center;
}

.kontakt_footer div {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 6%;
}

.kontakt_footer .icons_kontakt_footer .icon_fi_footer{
    width: 20%;
    height: 25px;
    color: #71cbc9;
}

.kontakt_footer .icons_kontakt_footer div{
    color: white;
    font-size: 16px;
    width: 80%;
}

.mreze_footer button {
    width: 20%;
    border: 0;
    border-radius: 50%;
    background: none;
    margin-left: 5%;
}

.mreze_footer button img {
    width: 100%;
    height: auto;
    border: 1px solid #71cbc9;
    border-radius: 50%;
}

.mreze_footer button:hover img{
    background: #71cbc9;
}

.mreze_footer a img {
    width: 100%;
}

.footer_responsive {
    display: none;
    text-align: center;
}

@media (max-width: 850px){
    .Footer1 {
        height: 700px;
    }

    .sajt_footer {
        display: none;
    }

    .kontakt_footer {
        display: none;
    }
    
    .footer_responsive {
        display: block;
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }

    .footer_responsive .mreze_footer{
        width: 40%;
        margin: 20px 0 0 29%;
    }

    .logo_footer {
        margin-top: 120px;
        width: 40%;
        margin-left: 31%;
    }
}

@media (max-width: 680px){
    .Footer1{
        height: 650px;
    }

    .footer_responsive .mreze_footer{
        width: 60%;
        margin: 20px 0 0 18%;
    }

    .logo_footer {
        margin-top: 120px;
        width: 50%;
        margin-left: 26%;
    }
}

@media (max-width: 500px){
    .Footer1{
        height: 600px;
    }

    .footer_responsive .mreze_footer{
        width: 70%;
        margin: 20px 0 0 13.5%;
    }

    .logo_footer {
        margin-top: 120px;
        width: 60%;
        margin-left: 21%;
    }
}

@media (max-width: 400px){

    .Footer1 {
        height: 550px;
    }
    
    .footer_responsive {
        display: block;
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }

    .footer_responsive .mreze_footer{
        width: 80%;
        margin: 20px 0 0 8%;
    }

    .logo_footer {
        margin-top: 120px;
        width: 60%;
        margin-left: 20%;
    }
}