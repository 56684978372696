.middle_repsus {
    width: 100%;
    background-image: url("/background-Dark-Grey.jpg");
    background-attachment: fixed;
    margin: 0;
    padding: 50px 0 80px 0;
}
.slider_img_repsus {
    display: flex;
    margin-left: 5.6%;
}

.repsus_response {
    display: none;
}
.slider_img_repsus div {
    -webkit-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    -moz-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
}

.slider_img_repsus .img1_repsus{
    border: 0;
    width: 30%;
    background: url("/repsus1.jpg");
    background-size: 100%;
    animation: slideImg1_repsus 20s infinite;
    padding-top: 19.84375%;
}

.slider_img_repsus .img2_repsus{
    border: 0;
    width: 30%;
    margin-left: 1%;
    background: url("/repsus2.jpg");
    background-size: 100%;
    animation: slideImg2_repsus 20s infinite;
}

.slider_img_repsus .img3_repsus{
    border: 0;
    margin-left: 1%;
    width: 30%;
    background: url("/repsus3.jpg");
    background-size: 100%;
    animation: slideImg3_repsus 20s infinite;
}

.tekstProjekti {
    /* text-align: center; */
    color:white;
    margin-left: 10%;
    padding-bottom: 5%;
    font-size: 18px;
    width: 80%;
}
#repsusTekst {
    color:#71cbc9;
}

.repsus {
    width: 20%;
    margin-left: 40%;
    padding-bottom: 2%;
}

@keyframes slideImg1_repsus {
    25% {
        background: url("/repsus2.jpg");
        background-size: 100%;
    }
    50% {
        background: url("/repsus3.jpg");
        background-size: 100%;
    }
    75% {
        background: url("/repsus4.jpg");
        background-size: 100%;
    }
    100% {
        background: url("/repsus1.jpg");
        background-size: 100%;
    }
}

@keyframes slideImg2_repsus {
    25% {
        background: url("/repsus3.jpg");
        background-size: 100%;
    }
    50% {
        background: url("/repsus4.jpg");
        background-size: 100%;
    }
    75% {
        background: url("/repsus1.jpg");
        background-size: 100%;
    }
    100% {
        background: url("/repsus2.jpg");
        background-size: 100%;
    }
}

@keyframes slideImg3_repsus {
    25% {
        background: url("/repsus4.jpg");
        background-size: 100%;
    }
    50% {
        background: url("/repsus1.jpg");
        background-size: 100%;
    }
    75% {
        background: url("/repsus2.jpg");
        background-size: 100%;
    }
    100% {
        background: url("/repsus3.jpg");
        background-size: 100%;
    }
}

@media (max-width: 1120px){
    .repsus_comp {
        display: none;
    }
    .repsus_response {
        display: block;
    }

    .repsus {
        width: 50%;
        margin-left: 25%;
        padding-bottom: 5%;
    }

    .tekstProjekti {
        /* text-align: justify; */
        font-size: 15px;
    }
}