.Middle_timovi {
    width: 100%;
    background-image: url("/background-Dark-Grey.jpg");
    background-attachment: fixed;
    margin: 0;
    padding: 50px 0 80px 0;

}

.naslov_timovi {
    display: flex;
    padding-bottom: 70px;

}

.naslov_timovi div1{
    width: 90%;
    border-bottom: 2px solid white;
}

.naslov_timovi div {
    width: 100%;
    z-index: 1;
    color: white;
    text-align: center;
    margin-bottom: -33px;
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.clanovi_timovi {
    width: 100%;
    margin: 30px 0 0 0;
    display: flex;
}

.clanovi_timovi .clan_t {
    width: 18%;
    margin-left: 5.6%;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.clanovi_timovi img{
    width: 80%;
    margin: 15px 0 5px 0;
    border: 2px solid #71cbc9;
    -webkit-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    -moz-box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    box-shadow: 3px 0px 37px 3px rgba(113,203,201,1);
    border-radius: 50%;
}

.clanovi_timovi img:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.tl_clan {
    color: #71cbc9;
    font-size: 17px;
    margin-bottom: -10px;
    margin-top: -10px;
}

@media (max-width: 850px){

    .clanovi_timovi .clan_t {
        width: 21%;
        margin-left: 3.2%;
    }
    .naslov_timovi div1{
        display: none;
    }
}

@media (max-width: 700px){

    .clanovi_timovi {
        margin: 70px 0 0 0;
    }

    .clanovi_timovi .clan_t {
        width: 24%;
        margin-left: 0.8%;
    }
}

@media (max-width: 500px){

    .naslov_timovi {
        margin: -30px 0 60px 0;
    }

    .clanovi_timovi {
        display: block;
        width: 70%;
        margin: 10px 0 0 15%;
    }

    .clanovi_timovi .clan_t {
        width: 100%;
        margin: 0 0 20px 0;
    }
}